import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Span, Box, Image, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"overdimple"} />
		<Helmet>
			<title>
				dimple - Over ons
			</title>
			<meta name={"description"} content={"dimple staat voor \"digital simplicity\"\nom productiever en creatiever (samen) te werken\naan projecten die er toe doen"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam />
		<Section color="--dark" padding="24px 24px 24px 24px" margin="0px 0 0 0">
			<Override slot="SectionContent" lg-max-width="100%" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Over dimple
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
				width="100% border-box"
			>
				<Text as="h3" font="--headline3" margin="10px 0" text-align="center">
					Waarom dimple?
				</Text>
				<Text
					as="p"
					font="--base"
					margin="10px 0"
					color="--greyD3"
					text-align="center"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						dimple staat voor "digital simplicity"
						<br />
						dimple staat voor de rimpels die samenwerking teweeg brengt
						<br />
						dimple staat voor het lachkuiltje op het gelaat van tevreden medewerkers
						<br />
					</Strong>
					<br />
					Hoe besteed ik mijn tijd, aandacht en energie productief?  Op een manier die duurzaam is én voldoening geeft?  Hoe hou ik overzicht?  Hoe onthou ik wat van belang is en wat me boeit?  Hoe verlicht ik mijn - bij momenten drukke - hoofd?  Deze vragen houden me al enkele jaren bezig.  Ik las veel en experimenteerde met{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						verschillende productivteits-methodes
					</Strong>
					{" "}(Time management met Eisenhouwer matrix, Zen to Done, GTD, ...) en -
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						tools{" "}
					</Strong>
					(bullet journalling

Evernote, Trello, Todoist, Kanban, ...).  Uit eigen evaring leerde ik wat me vooruit hielp, en wat net niet.
					<br />
					<br />
					Ook binnen bedrijven en organisaties zie ik hoe medewerkers, managers, teams en organisaties worstelen met deze vragen. De combinatie van opeenvolgende ingebruikname van verschillende applicaties en communicatie-kanalen, hoge werk- en communicatiesnelheid leidt tot{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						digitale overload en fragmentatie{" "}
					</Strong>
					.
					<br />
					<br />
					Hierdoor wordt onze{" "}
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							aandacht voortdurend afgeleid{" "}
						</Strong>
						en{" "}
					</Span>
					dreigen we de{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						essentie{" "}
					</Strong>
					te missen.  We lijken vandaag collectief meer dan ooit op zoek naar eenvoud, overzicht, samenhang en focus.{"  "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<br />
					</Strong>
				</Text>
			</Box>
			<Box
				margin="36px 0 10px 0"
				padding="0 0 0 0px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 36px 0"
			>
				<Text as="h3" font="--headline3" margin="10px 0" text-align="center">
					Wie is Dirk?
				</Text>
				<Image
					src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12:37:19.586Z"
					width="250px"
					text-align="left"
					display="block"
					object-fit="fill"
					max-width="none"
					align-items="center"
					justify-content="center"
					align-content="center"
					flex-direction="row"
					min-width="none"
					min-height="none"
					height="auto"
					disableOptimization={false}
					pointer-events="none"
					left="40%"
					position="relative"
					margin="20px 0px 20px 0px"
					srcSet="https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/646a3bab16e21c0024b31727/images/dirk_2022_portret2.jpg?v=2024-04-18T12%3A37%3A19.586Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					as="p"
					font="--base"
					margin="10px 0"
					color="--greyD3"
					text-align="center"
					padding="0px 24px 0px 24px"
				>
					Ik ben Dirk Diddens, zaakvoerder van dimple.  Van jongs af aan ben ik gefascineerd door het zoeken naar{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						eenvoudige oplossingen voor complexe problemen
					</Strong>
					.  Als burgerlijk ingenieur (KULeuven '92) kreeg ik een grondige theoretische wetenschappelijk basis.  Nadien belandde ik in het bedrijfsleven waar ik meer{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						pragmatische en creatieve oplossings-methodes
					</Strong>
					{" "}ontdekte en verder ontwikkelde.  Ik merkte dat het ontwerpen van{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						innovatieve (digitale) producten
					</Strong>
					, samen met gedreven klanten en teams, datgene was wat ik het liefst deed.  Ik kreeg tijdens mijn loopbaan de kans om mee te werken aan het ontwerp en de ontwikkeling van grote websites en platformen, gevoed door content management systemen, dossier opvolgings systemen, interactieve intranetten, document management oplossingen, web applicaties,  ... Dat deed ik enkele jaren als "functioneel analist".  Nadien ondersteunde ik teams als "project manager" en "team coach" bij uiteenlopende klanten (groot én klein, profit én non-profit)  Ik bekwaamde me in
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}web-, intranet- en en usability design
					</Strong>
					.  En ik omarmde de{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						agile mindset
					</Strong>
					{" "}als aanpak voor productontwikkeling.  Ik behaalde{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						certificaten{" "}
					</Strong>
					als Agile Scrum Master, Product Owner, Agile Team coach.
					<br />
					<br />
					Daarnaast startte ik 2014 een 4-jarige gestalttherapie-opleiding.  Sinds 2017 houd ik (deeltijds) praktijk als psycho-therapeut in Aalst.  Ik volgde diverse vervolmakingsopleidingen (oa. ACT, Socratische gesprekstechnieken, ...).  Mijn aandacht richt zich steeds op{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						resultaat én mens
					</Strong>
					.  Een tool, hoe krachtig ook, staat niet op zich.  Het is slechts een middel, ten dienste van mensen en organisaties.  Heldere doelen, een collaboratieve mindset en gedrag, duidelijke en aangepaste processen, ... zijn noodzakelijk om een gewenste en duurzame iimpact te bereiken.  Naast het ontwerpen en bouwen van digitale werkplekken op maat, zet ik in op de{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						begeleiding van klanten bij deze (menselijke) veranderingsprocessen
					</Strong>
					.
					<br />
					<br />
					Ik ben getrouwd en heb 3 dochters.  Ik hou van hardlopen, gitaar spelen, literatuur en non-fictie, studeren (filosofie, psychologie) en goede koffie.  Ik woon in Asse (bij Brussel).{"  "}
					<br />
					Voor meer details, zie mijn{" "}
					<Link
						href="https://www.linkedin.com/in/dirkdiddens/"
						target="_blank"
						text-decoration-line="initial"
						color="--primary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							LinkedIn{" "}
						</Strong>
					</Link>
					profiel.  Curriculum vitae op aanvraag.
				</Text>
			</Box>
		</Section>
		<Components.Troeven>
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2024 Dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});